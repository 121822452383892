import React from "react"
import { Link, graphql } from "gatsby";
import '../layouts/tachyons/css/tachyons.css';
import '../layouts/custom.css';
import SEO from "../components/seo"
import { BtnOutline } from "../components/button"
import { InterMedium} from "../components/typography"
import Nav from "../components/nav"
import CarbonAd from "../components/carbonad";
import {SubscribeOnly} from "../components/subscribe"


function slugify(text)
{
  return text.toString().toLowerCase()
    .replace(/\s+/g, '-').replace(/[^\w-]+/g, '').replace(/--+/g, '-').replace(/^-+/, '').replace(/-+$/, '');           
    // Replace spaces with -
    // Remove all non-word chars
    // Replace multiple - with single -
    // Trim - from start of text
    // Trim - from end of text
}

const IndexPage = ({ data }) => (
  <div className="qn-bg-dark1">
    <SEO title="All Highlights" description="Insights Hunt highlights key insights from UI UX design articles to help designers in getting new ideas or perspectives" />
    <Nav active="latest"/>
    <div className="center mw9 mt16-l mt8">

    <div className="fl w-70-l w-100-m w-100 pl32-l pr32-l ph32-m ph24">
      <div>  
        {data.allDatoCmsArticle.edges.map(({ node:article }) => (
        <Link to={slugify(article.title)}>
        <div className="quoteContainer grow pt48-l pb48-l pt32-m pb32-m pt24 pb24 ph48-l ph32-m ph24 mh0-l mh0-m mh0 mb24-l mb24-m mb16 br3" style={{backgroundColor: article.backgroundcolor.hex , color: article.textcolor.rgb }}> 
          <h2 className="inter f3-l f3 mb0 mt0 qn-lh-tight qn-ls-condensed fw7 mb16 qn-link-title qn-transition-op">
            <Link to={slugify(article.title)}>
              {
                article.quote.split("; ")[0]
              }
            </Link>
          </h2>
          <div className="w-100 flex-l">
            <div className="w-70-l w-70-m w-100 db">
              <p className="ibmplexmono f7-l f6 mb0 mt0 qn-lh-normal qn-ls-normal fw5 pl4 qn-link"><Link to={`/category/${slugify(article.category.title,{lower: true})}`}>{article.category.title}</Link></p>
                  <br/>
                  <p className="ibmplexmono f7-l f6 mb0 mt0 qn-lh-normal qn-ls-normal fw3 pl4 truncate w-100-l db-l dn">
                    by {article.author.name} <br/> in {article.title}
                  </p>
                  <p className="ibmplexmono f7-l f6 mb0 mt0 qn-lh-normal qn-ls-normal fw3 pl4 truncate w-100-l dn-l db">
                    by {article.author.name}
                  </p>
                  <p className="ibmplexmono f7-l f6 mb0 mt0 qn-lh-normal qn-ls-normal fw3 pl4 truncate w-100-l dn-l db">
                    in {article.title}
              </p>
            </div>
            <div className="w-30-l self-end actionButtons">
              <a className="qn-btn-round br-pill f5 pointer dib qn-transition-bg qn-btn-small mr fr" href={"https://twitter.com/intent/tweet?text=" + article.quote + "– from &url=" + article.url + "&via=insights_hunt"} style={{fill: article.alltextcolor.hex }} target="_blank" rel="noopener noreferrer">
                <InterMedium className="fw6 lh-solid">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                    <path d="M8.2896 20.251C15.8368 20.251 19.9648 13.9982 19.9648 8.57584C19.9648 8.39824 19.9648 8.22144 19.9528 8.04544C20.7559 7.46457 21.4491 6.74534 22 5.92144C21.2511 6.25328 20.4567 6.4709 19.6432 6.56704C20.4998 6.05423 21.1409 5.24766 21.4472 4.29744C20.6417 4.7754 19.7605 5.11225 18.8416 5.29344C18.2229 4.63559 17.4047 4.19997 16.5135 4.05401C15.6223 3.90805 14.7078 4.05987 13.9116 4.48598C13.1154 4.9121 12.4819 5.58875 12.109 6.41123C11.7361 7.23371 11.6446 8.15616 11.8488 9.03584C10.2174 8.95405 8.62144 8.5301 7.16451 7.79151C5.70759 7.05292 4.42227 6.01619 3.392 4.74864C2.86727 5.65197 2.70656 6.72133 2.94258 7.73899C3.17861 8.75665 3.79362 9.6461 4.6624 10.2262C4.00939 10.2069 3.37062 10.0307 2.8 9.71264C2.8 9.72944 2.8 9.74704 2.8 9.76464C2.80026 10.712 3.12821 11.6301 3.72823 12.3633C4.32824 13.0964 5.16338 13.5994 6.092 13.787C5.4879 13.9518 4.85406 13.9759 4.2392 13.8574C4.50141 14.6728 5.01189 15.3858 5.69926 15.8967C6.38662 16.4076 7.21649 16.691 8.0728 16.707C6.61979 17.849 4.82485 18.4689 2.9768 18.467C2.65032 18.4664 2.32416 18.4466 2 18.4078C3.87651 19.6121 6.05993 20.2508 8.2896 20.2478"/>
                  </svg>
                </InterMedium>
              </a>
              <a className="qn-btn-round br-pill f5 pointer dib qn-transition-bg qn-btn-small mr8 fr" href={article.url+"/?ref=insightshunt"} target="_blank" rel="noopener noreferrer" style={{fill: article.alltextcolor.hex }}>
                <InterMedium className="fw6 lh-solid">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h5c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1v5c0 .55-.45 1-1 1zM14 4c0 .55.45 1 1 1h2.59l-9.13 9.13c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L19 6.41V9c0 .55.45 1 1 1s1-.45 1-1V4c0-.55-.45-1-1-1h-5c-.55 0-1 .45-1 1z"/></svg>
                </InterMedium>
              </a>
              <a className="qn-btn-round br-pill f5 pointer dib qn-transition-bg qn-btn-small mr8 fr" href={'../../'+slugify(article.title)} style={{fill: article.alltextcolor.hex }}>
                <InterMedium className="fw6 lh-solid">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/></svg>
                </InterMedium>
              </a>
            </div>
          </div>
        </div>
        </Link>
        ))}

        </div>
        
        <div className="subscribeContainer pt48-l pb24-l pt32-m pb32-m pt24 pb24 ph48-l ph32-m ph24 mh0-l mh0-m mh0 mb24-l mb24-m mb16 br3" style={{backgroundColor: 'transparent' , color: '#ffffff' }}> 
          <h2 className="white inter f3-l f4 mb0 mt0 qn-lh-tight qn-ls-condensed fw7 mb16">Subscribe to our newsletter for weekly design highlights!</h2>
          <p className="white ibmplexmono f6-l f6 mb0 mt0 qn-lh-normal qn-ls-normal fw3 mb16">I promise, I won't spam you.</p>
          <SubscribeOnly/>
        </div>

        <div className="ph0-l ph32-m ph0 mh0-l mh0-m mh0 mb48-l mb48">      
            <Link to={"/page/1"} rel="next">
              <BtnOutline className="fr small">Next</BtnOutline>
            </Link>
          <div className="cb"></div>
        </div>
      </div>
      

      {/* STICKY SIDEBAR */}
      <div className="fl db-l dn w-30-l w-0 white sticky-sidebar pl24-l pr32-l">
        {/*<ul className="qn-category-tab-desktop db mb0 pb8">
          <li className="db">
            <Link to="/all">
              <p className="inter f7-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-white qn-transition-cl">All</p>
            </Link>
          </li>
          {data.getcategories.group.map(({ edges }) => (
          <li className="db mr32">
            <Link to={`/category/${slugify(edges[0].node.category.title,{lower: true})}`}><p className="inter f7-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">{edges[0].node.category.title}</p></Link> 
          </li>   
          ))}
          </ul> <hr className="DividerNormal bb qn-br-dark2 mt32"/> */}
        <ul className="qn-category-tab-desktop db mb0 pb8">
          <li className="db">
            <Link to="/">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">All</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/design">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Design</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/research">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Research</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/process">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Process</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/ux-writing">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">UX Writing</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/personal-growth">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Personal Growth</p>
            </Link>
          </li>
          <li className="db">
            <Link to="/category/leadership">
              <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Leadership</p>
            </Link>
          </li>

          <li className="db">
            <div className="dropdown">
              <button className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl dropbtn">More...</button>
                <div className="dropdown-content">
                  <Link to="/category/career">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Career</p>
                  </Link>
                  <Link to="/category/creativity">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Creativity</p>
                  </Link>
                  <Link to="/category/design-system">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Design System</p>
                  </Link>
                  <Link to="/category/freelance">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Freelance</p>
                  </Link>
                  <Link to="/category/product">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Product</p>
                  </Link>
                  <Link to="/category/prototyping">
                    <p className="inter f6-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6">Prototyping</p>
                  </Link>
                </div>
            </div>
          </li>
          </ul> 
          
          <hr className="DividerNormal bb qn-br-dark2"/>
          <ul className="qn-category-tab-desktop db">
            <li className="dib mr8 mb0">
              <Link to="/subscribe">
                <p className="inter f7-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Subscribe</p>
              </Link>
            </li>
            <li className="dib mr8 mb0">
            ·
            </li>
            <li className="dib mr8 mb0">
              <Link to="/about">
                <p className="inter f7-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">About</p>
              </Link>
            </li>
            <li className="dib mr8 mb0">
            ·
            </li>
            <li className="dib mb24">
              <a href="https://chrome.google.com/webstore/detail/insights-hunt/mcpkoiegngiibjaedneiglaepmfggdhb" target="_blank" rel="noreferrer">
                <p className="inter f7-l f6 mb0 mt0 qn-lh-normal qn-ls-tight fw6 qn-cl-grey1 qn-transition-cl">Extension</p>
              </a>
            </li>
          </ul>
          <a href="https://nucleus-ui.com/shop" rel="noreferrer" target="_blank"><img src="https://previews.dropbox.com/p/thumb/ABe-f_LxohvK_YGojZi4Avw-mt1tkFA36pkGuL978wwQo13Do32YJnP_konKyQ_SKVHGFkaUIfJxs5nWvUE3Svs_AYcgy7tNUPzJGmfFqE9f--9XCN_BD_8eyMTiGEG-1TJdi0_pvr4ra8_SRHlA8hUN9FpgX2g2VpkLppgv6fV4oOf5UzXyGNY5RRT5pcRgaRz7I2cIJzTsEj-ryi8dyoCbtM5eShojyqFLxq5Ihc10TiME4uTzV5vinynTUK1QkugifqRq_Ea-j0-KAzX9mTdijANT7uiTWbOa_NZauqlVgzEHV9Pr2oy-T6sjcxTC8HTpIj6OGjWlvCuQMqbacG8j2CWoouj1UpxyoCGiZ4gotZZuFMLBftUukK7kLljgsQw/p.png" alt="nucleus banner" width="80%"/></a>
          <CarbonAd/>
        </div>
        {/* STICKY SIDEBAR */}

        <div>
          
        </div>
      <div className="cb"></div>
    </div>
  </div>
)

export default IndexPage

export const query = graphql`
  query getToday {
    allDatoCmsArticle(sort: {order: DESC, fields: number}, limit: 7) {
      edges {
        node {
          id
          author {
            name
          }
          source{
            name
          }
          title
          quote
          url
          category {
            title
          }
          backgroundcolor {
            hex
          }
          alltextcolor {
            hex
          }
          textcolor {
            rgb
          }
        }
      }
    }
  }
`




